@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c1c1c1;
  background-color: #0a0103;
  max-width: 1280px;
  margin: auto; 
}

@layer components{
  .ani {
    @apply transition-all ease-linear;
  }
  .btn {
    @apply text-xs p-1 cursor-pointer uppercase tracking-widest font-semibold text-center border-b-2 border-transparent ani hover:border-b-2 hover:border-[#c1c1c1];
  }
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  border-radius:10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  right:0px;
  background: transparent;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius:0px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
  border-radius:0px;
}